import * as React from 'react';
import tw from 'twin.macro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import Title from '../components/title';
import ContentWidth from '../components/contentWidth';

type Props = {
  location: Location;
};

function Contact({ location }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.contact.path}
    >
      <SEO
        featuredImage={seoData.contact.featuredImage?.[defaultLangKey]}
        title={seoData.contact.title[defaultLangKey]}
        description={seoData.contact.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <ContentWidth>
        <Title title={`お問い合わせフォーム`} />
        <section css={tw`mt-12 max-w-screen-md mx-auto`}>
          <form
            name="contact"
            css={tw`w-full mb-12 md:mb-24 `}
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <div css={tw`flex flex-wrap -mx-3 mb-6`}>
              <p
                css={tw`w-full px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
              >
                お問い合わせ内容を選択してください。
              </p>
              <div css={tw`w-full md:w-1/2 px-3`}>
                <input
                  type="radio"
                  id="reservation"
                  name="subject"
                  value="reservation"
                  css={tw`mr-2 cursor-pointer`}
                />
                <label
                  htmlFor="reservation"
                  css={tw`tracking-wide text-gray-700 text-xs font-bold mb-2 cursor-pointer`}
                >
                  カウンセリング予約
                </label>
              </div>
              <div css={tw`w-full md:w-1/2 px-3`}>
                <input
                  type="radio"
                  id="inquiry"
                  name="subject"
                  value="inquiry"
                  defaultChecked={true}
                  css={tw`mr-2 cursor-pointer`}
                />
                <label
                  htmlFor="inquiry"
                  css={tw`tracking-wide text-gray-700 text-xs font-bold mb-2 cursor-pointer`}
                >
                  お問い合わせ
                </label>
              </div>
            </div>
            <div css={tw`flex flex-wrap -mx-3 mb-2`}>
              <div css={tw`w-full md:w-1/2 px-3`}>
                <label
                  css={tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
                  htmlFor="last-name"
                >
                  姓(<span css={tw`text-red-400`}>*</span>)
                </label>
                <input
                  css={tw`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="last-name"
                  type="text"
                  required={true}
                  name="last-name"
                  placeholder="山田"
                />
              </div>
              <div css={tw`w-full md:w-1/2 px-3`}>
                <label
                  css={tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
                  htmlFor="first-name"
                >
                  名(<span css={tw`text-red-400`}>*</span>)
                </label>
                <input
                  css={tw`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="first-name"
                  type="text"
                  required={true}
                  name="first-name"
                  placeholder="太郎"
                />
              </div>
            </div>
            <div css={tw`flex flex-wrap -mx-3 mb-2`}>
              <div css={tw`w-full px-3`}>
                <label
                  css={tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
                  htmlFor="address"
                >
                  ご住所(任意)
                </label>
                <input
                  css={tw`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="address"
                  name="address"
                  type="text"
                />
              </div>
            </div>
            <div css={tw`flex flex-wrap -mx-3 mb-2`}>
              <div css={tw`w-full px-3`}>
                <label
                  css={tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
                  htmlFor="tel"
                >
                  TEL(任意)
                </label>
                <input
                  css={tw`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="grid-address"
                  type="tel"
                  name="tel"
                />
              </div>
            </div>
            <div css={tw`flex flex-wrap -mx-3 mb-2`}>
              <div css={tw`w-full px-3`}>
                <label
                  css={tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
                  htmlFor="email"
                >
                  メールアドレス(<span css={tw`text-red-400`}>*</span>)
                </label>
                <input
                  css={tw`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="grid-address"
                  required={true}
                  type="email"
                  name="email"
                />
              </div>
            </div>
            <div css={tw`flex flex-wrap -mx-3 mb-2`}>
              <div css={tw`w-full px-3`}>
                <label
                  css={tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
                  htmlFor="message"
                >
                  お問い合わせ内容(<span css={tw`text-red-400`}>*</span>)
                </label>
                <textarea
                  css={tw`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48`}
                  id="message"
                  name="message"
                />
              </div>
            </div>

            <div css={tw`flex flex-wrap -mx-3 mb-2`}>
              <div css={tw`w-full px-3`}>
                <button
                  type="submit"
                  css={tw`py-5 px-12 rounded bg-blue-600 text-white text-sm font-bold focus:bg-blue-500 active:bg-blue-500 hover:bg-blue-500`}
                >
                  送信する
                </button>
              </div>
            </div>
          </form>

          <div
            css={tw`bg-gray-200 p-5 mb-5 rounded font-bold text-sm leading-relaxed`}
          >
            <p>※お電話によるお問い合わせには営業時間内、</p>
            <p>
              E-mailやフォームを通じてのお問い合わせには営業時間外（祝日を除く）にも対応させていただきます。
            </p>
          </div>

          <div css={tw`bg-gray-200 p-5 rounded font-bold text-sm`}>
            <p>営業時間</p>
            <p>土・日: 9:00〜18:00</p>
            <p>（平日・祝日・年末年始は休業日となっております）</p>
          </div>
        </section>
      </ContentWidth>
    </Layout>
  );
}

export default Contact;
